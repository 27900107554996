import React, { useEffect, useState } from "react";
import { OptinsService } from "src/services/OptinsService";
import { FixMeLater, OptinsExportState } from "src/types";
import { OptinsCompareResponse } from "src/types/OptinsService.types";
import { downloadFile } from "src/utils";
import CustomSnackbar from "../CustomSnackbar/CustomSnackbar";
import Loader from "../Loader/Loader";
import "./OptinsExport.scss";
import {
    StyledExportButton,
    StyledExportButtonText,
    StyledOptinsContainer,
} from "./OptinsExport.styled";
import Compare from "./components/Compare/Compare";
import Instructions from "./components/Instructions/Instructions";

import {
    LayoutPaneTabPanel as TabPanel,
    LayoutPaneTab as Tab,
    LayoutPaneTabs as Tabs,
} from "src/uikit";
import { Box } from "@mui/material";

const OptinsExport = ({ open, onClose, payload, validationResponse }) => {
    const [isFetching, setIsFetching] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [snackbarOpen, setSnackbarOpen] = useState<boolean>(false);
    const [snackbarMessage, setSnackbarMessage] = useState<string>("");
    const [snackbarSeverity, setSnackbarSeverity] = useState<string>("error");

    const [optinsExport, setOptinsExport] = useState<OptinsExportState>({
        selectedTab: "Instructions",
        compare: [],
    });

    const optinsService = OptinsService.getInstance();

    useEffect(() => {
        const fetchData = async () => {
            setIsFetching(true);
            try {
                const compare: OptinsCompareResponse[] =
                    await optinsService.compare(payload);
                setOptinsExport({ ...optinsExport, compare });
            } catch (error: FixMeLater) {
                handleSnackbar(
                    `There has been an error fetching data. ${error?.message}`,
                    "error"
                );
            } finally {
                setIsFetching(false);
            }
        };

        if (open && validationResponse.successfull) {
            fetchData();
        }
    }, [open]);

    const handleExport = async () => {
        setIsLoading(true);
        try {
            const response = await optinsService.export(payload);

            let fileName = "OPTinsExport.xlsx";
            const contentDispositionHeader = response.headers.get(
                "Content-Disposition"
            );

            if (contentDispositionHeader) {
                const match =
                    contentDispositionHeader.match(/filename=([^;]+)/);
                fileName = match?.[1] ?? fileName;
            }

            const blob = new Blob([await response.blob()], {
                type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
            });

            downloadFile(blob, fileName);
            onClose();
            handleSnackbar(
                `Optins: ${fileName} downloaded successfully`,
                "success"
            );
        } catch (error: FixMeLater) {
            console.error("Error exporting data:", error);
            handleSnackbar(
                `There has been an error exporting the data. ${
                    error?.message || error
                }`,
                "error"
            );
        } finally {
            setIsLoading(false);
        }
    };

    const handleSnackbar = (message: string, severity: string) => {
        setSnackbarMessage(message);
        setSnackbarSeverity(severity);
        setSnackbarOpen(true);
    };

    const ActionButtons: React.FC = () => {
        return (
            <Box
                sx={{
                    display: "flex",
                    justifyContent: "end",
                }}
            >
                <StyledExportButton>
                    <StyledExportButtonText
                        variant="contained"
                        onClick={handleExport}
                    >
                        Export
                    </StyledExportButtonText>
                </StyledExportButton>
            </Box>
        );
    };

    return (!validationResponse.successful?
        <div className="optins-tabpanel-errors">
            <span className="optins-tabpanel-title">
                The following requirements have not been met.
            </span>
            <ul>
                {validationResponse?.failures?.map(
                    (response, index) => (
                        <li key={`attachment${index}`}>
                            {response.message}
                        </li>
                    )
                )}
            </ul>
        </div>
        :<>
            <div className="optins-export-container">
                <StyledOptinsContainer>
                    {isFetching || isLoading ? (
                        <Loader />
                    ) : (
                        <>
                            <Tabs
                                orientation="horizontal"
                                value={optinsExport?.selectedTab}
                                onChange={(event, newValue) => {
                                    setOptinsExport({
                                        ...optinsExport,
                                        selectedTab: newValue,
                                    });
                                }}
                            >
                                <Tab
                                    selectedTab={optinsExport?.selectedTab}
                                    value="Instructions"
                                >
                                    Instructions
                                </Tab>
                                <Tab
                                    selectedTab={optinsExport?.selectedTab}
                                    value="Compare"
                                >
                                    Compare
                                </Tab>
                            </Tabs>
                            <Box
                                sx={{
                                    display: "flex",
                                    flexDirection: "column",
                                    padding: "24px",
                                    overflowY: "auto",
                                }}
                            >
                                <TabPanel
                                    selectedTab={optinsExport?.selectedTab}
                                    value="Instructions"
                                >
                                    <Instructions />
                                    <ActionButtons />
                                </TabPanel>
                                <TabPanel
                                    selectedTab={optinsExport?.selectedTab}
                                    value="Compare"
                                >
                                    <Compare optinsExport={optinsExport} />
                                    <ActionButtons />
                                </TabPanel>
                            </Box>
                        </>
                    )}
                </StyledOptinsContainer>
            </div>
            <CustomSnackbar
                open={snackbarOpen}
                setOpen={setSnackbarOpen}
                message={snackbarMessage}
                severity={snackbarSeverity}
            />
        </>
    );
};

export default OptinsExport;
