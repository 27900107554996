import styled from "styled-components";

export const StyledDragAndDrop = styled("div")`
    box-sizing: border-box;
    border-radius: 2px;
    width: 100%;
    height: 100%;
    min-height: 116px;
    cursor: pointer;
    background-color: ${({ theme }) => theme.palette.primary};
    border: ${({ theme }) => `1px solid ${theme.colors.white}`};
`;

export const StyledUploadContainer = styled("div")`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    color: ${({ theme }) => theme.palette.textColor};

    .MuiSvgIcon-root {
        color: ${({ theme }) => theme.colors.white};
    }

    p {
        margin: 12px 25px 0;
        text-align: center;
    }
`;