import e from "express";
import {
    ExtMessageResponse,
    ExtMessage,
    ExtAction,
    InputData,
    LoginData,
    ExtMessageResponseStatus,
} from "src/types";

export class WebExtensionService {
    private static _instance: WebExtensionService;

    private constructor() {}

    get browser() {
        return window.chrome || window["browser"];
    }

    get extensionId() {
        let extensionId = sessionStorage.getItem("tts-ext-id") || undefined;
        return extensionId;
    }
    private sendMessage = async (
        message: ExtMessage,
    ): Promise<ExtMessageResponse> => {
        const sendMessageResponse: ExtMessageResponse = {
            status: ExtMessageResponseStatus.ERROR,
            message: "Error while sending message: ",
        };

        if (!this.extensionId) {
            sendMessageResponse.message += " Extension id not found";
            throw sendMessageResponse;
        }

        return new Promise((resolve, reject) => {
            this.browser?.runtime.sendMessage<
                ExtMessage,
                ExtMessageResponse
            >(this.extensionId, message, (response) => {
                if (this.browser.runtime.lastError) {
                    sendMessageResponse.message +=
                        this.browser.runtime.lastError.message;
                    reject(sendMessageResponse);
                } else {
                    resolve(response);
                }
            });
        });
    };

    public openNewTab = async (
        url: string,
        callback?: (response: ExtMessageResponse) => void,
    ): Promise<ExtMessageResponse> => {
        let response: ExtMessageResponse = {
            status: ExtMessageResponseStatus.ERROR,
            message: "Error while opening new tab",
        };

        const message: ExtMessage = {
            action: ExtAction.STRATUS_CREATE_TAB,
            data: {
                url: url,
            },
        };

        try {
            const messageResponse = await this.sendMessage(message).catch(
                (error) => {
                    console.log(
                        "sendMessage error catch message: ",
                        error.message,
                    );
                    if (error.message.includes("Extension id not found")) {
                        response.message =
                            "Tritech browser extension is not installed";
                    } else {
                        response.message = error.message;
                    }
                },
            );

            if (messageResponse) {
                if (callback) {
                    callback(messageResponse);
                }
                response = messageResponse;
            }
        } catch (error) {
            if (error instanceof Error) {
                console.error(
                    "[ WebExtensionService ] :: Error sending open new tab action :: ",
                    error.message,
                );
            }
        }

        return response;
    };

    public fillLogin = async (
        tabId: number,
        inputData: InputData[],
        callback?: (response: ExtMessageResponse) => void,
    ): Promise<ExtMessageResponse> => {
        let response: ExtMessageResponse = {
            status: ExtMessageResponseStatus.ERROR,
            message: "Error while fill login data",
        };

        const data: ExtMessage = {
            action: ExtAction.STRATUS_FILL_DATA,
            data: {
                tabId: tabId,
                inputData: inputData,
            },
        };

        try {
            const messageResponse = await this.sendMessage(data).catch(
                (error) => {
                    console.log(
                        "sendMessage error catch message: ",
                        error.message,
                    );
                    if (error.message.includes("Extension id not found")) {
                        response.message =
                            "Tritech browser extension is not installed";
                    } else {
                        response.message = error.message;
                    }
                },
            );

            if (messageResponse) {
                if (callback) {
                    callback(messageResponse);
                }
                response = messageResponse;
            }

        } catch (error) {
            console.error(
                "[ WebExtensionService ] :: Error sending fill login action :: ",
                error,
            );
            response = {
                status: ExtMessageResponseStatus.ERROR,
                message: "Error while filling login",
            };
        }

        return response;
    };

    public doLogin = async (data: LoginData): Promise<ExtMessageResponse> => {
        let response: ExtMessageResponse;

        try {
            const openNewTabReponse: ExtMessageResponse<{ tabId: number }> =
                await this.openNewTab(data.url);

            if (
                openNewTabReponse.status === ExtMessageResponseStatus.SUCCESS &&
                openNewTabReponse.data &&
                openNewTabReponse.data.tabId
            ) {
                await new Promise((resolve) => setTimeout(resolve, 4000));
                response = await this.fillLogin(
                    openNewTabReponse.data.tabId,
                    data.inputData,
                );
            } else {
                if (
                    openNewTabReponse.message ===
                    "Tritech browser extension is not installed"
                ) {
                    response = {
                        status: ExtMessageResponseStatus.ERROR,
                        message: "Tritech browser extension is not installed",
                    };
                } else {
                    response = {
                        status: ExtMessageResponseStatus.ERROR,
                        message: "Error while opening new tab",
                    };
                }
            }
        } catch (error) {
            response = {
                status: ExtMessageResponseStatus.ERROR,
                message: "Error while logging in",
            };
        }

        return response;
    };

    public static getInstance(): WebExtensionService {
        if (!WebExtensionService._instance) {
            WebExtensionService._instance = new WebExtensionService();
        }
        return WebExtensionService._instance;
    }
}
